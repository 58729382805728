import React from "react";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";

const KeyDialog = ({showKey, setShowKey,  keyContent}) => {

	const handleClose = () => {
		setShowKey(false);
	}

	return (
		<Dialog
			fullScreen
			open={showKey}
			onClose={() => handleClose()}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<List>
				<ListItem>
					<IconButton edge="start" onClick={() => handleClose()} aria-label="close">
						<CloseIcon />
					</IconButton>
				</ListItem>
				<ListItem>
					<span dangerouslySetInnerHTML={{__html: keyContent}} />
				</ListItem>
				<ListItem>
					<IconButton edge="start" onClick={() => handleClose()} aria-label="close">
						<CloseIcon />
					</IconButton>
				</ListItem>
			</List>
		</Dialog>
	)
}

export default KeyDialog;