import React, {useEffect, useState} from "react";
import {Loading} from "./Loading";
import Key from "./Key";
import KeyService from "./KeyService";
import TextField from "@material-ui/core/TextField";
import Grid from "@material-ui/core/Grid";
import ListItem from "@material-ui/core/ListItem";
import {MenuItem, Select, TablePagination} from "@material-ui/core";

const Keys = () => {

	const [keys, setKeys] = useState([]);
	const [loading, setLoading] = useState(true);

	const [page, setPage] = useState(0);
	const [count, setCount] = useState(0);
	const [perPage, setPerPage] = useState(25);

	const [searchTitle, setSearchTitle] = useState("");

	const getRequestKeysParams = (page, pageSize) => {
		let params = {};
		params["topic.forum.id[]"] = 82;
		params["order[topic.title]"] = "asc";

		if (page) {
			params["page"] = page + 1;
		}

		if (pageSize) {
			params["perPage"] = perPage;
		}

		params["exists[githubUrl]"] = true;

		if (searchTitle) {
			params["topic.title"] = searchTitle;
		}

		return params;
	};

	const retrieveKeys = () => {

		const params = getRequestKeysParams(page, perPage);

		KeyService.getKeys(params)
			.then((response) => {
				setKeys(response.data['hydra:member']);
				const count = response.data['hydra:totalItems'];
				setCount(count);
				setLoading(false);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(retrieveKeys, [searchTitle, page, perPage]);

	const handlePageChange = (event, value) => {
		setPage(value);
	};

	const handlePerPageChange = (event) => {
		setPerPage(event.target.value);
		setPage(0);
	};

	const onChangeSearchTitle = (e) => {
		const searchTitle = e.target.value;
		setSearchTitle(searchTitle);
	};

	if (loading) {
		return <Loading/>
	} else if (count === 0) {
		return <span/>
	} else {
		return (
			<span>
				<ListItem><h2>Clés de détermination</h2></ListItem>

				<ListItem>
					<Grid container spacing={3}>
						<Grid item xs={12} md={6}>
							<TextField
								variant="outlined"
								size="small"
								placeholder="Filtrer le titre..."
								value={searchTitle}
								onChange={(event) => onChangeSearchTitle(event)}
							/>
						</Grid>
					</Grid>
				</ListItem>

				<ListItem>
					<Select
						disableUnderline
						className="text-white"
						value={perPage}
						onChange={handlePerPageChange}
					>
						<MenuItem value={25}><small> 25</small></MenuItem>
						<MenuItem value={50}><small> 50</small></MenuItem>
						<MenuItem value={100}><small> 100</small></MenuItem>
						<MenuItem value={150}><small> 150</small></MenuItem>
					</Select>
					clés de détermination
				</ListItem>
					{keys.map((key) => (
						<Key key={key.id} element={key} />
					))}
					<TablePagination
						labelRowsPerPage="Nombre de clés:"
						component="ListItem"
						count={count}
						page={page}
						onChangePage={handlePageChange}
						rowsPerPage={perPage}
						onChangeRowsPerPage={handlePerPageChange}
						rowsPerPageOptions={[25, 50, 100, 150]}
					/>

			</span>
		)
	}
}

export default Keys;