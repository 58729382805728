import React, {useEffect, useState} from "react";
import KeyService from "./KeyService";
import axios from 'axios';
import fileDownload from 'js-file-download';
import ListItem from "@material-ui/core/ListItem";
import GetAppIcon from '@material-ui/icons/GetApp';
import VisibilityIcon from '@material-ui/icons/Visibility';
import ListItemIcon from "@material-ui/core/ListItemIcon";
import ListItemText from "@material-ui/core/ListItemText";
import GitHubIcon from '@material-ui/icons/GitHub';
import ForumIcon from '@material-ui/icons/Forum';
import IconButton from "@material-ui/core/IconButton";
import Alert from '@material-ui/lab/Alert';
import KeyDialog from "./KeyDialog";
import Button from "@material-ui/core/Button";
import SpeciesDialog from "./SpeciesDialog";
import {VisibilityOff} from "@material-ui/icons";

const Key = ({element}) => {

	const [showKey, setShowKey] = useState(false);
	const [showSpecies, setShowSpecies] = useState(false);
	const [showKeyId, setShowKeyId] = useState(null);
	const [keyContent, setKeyContent] = useState("");
	const [urlError, setUrlError] = useState(false);

	const retrieveKey = () => {
		if (showKeyId != null) {
			KeyService.getKey(showKeyId)
				.then((response) => {
					setKeyContent(response.data.key);
				})
				.catch((e) => {
					console.log(e);
				});
		}
	};

	useEffect(retrieveKey, [showKeyId]);

	const handleDownload = (url, filename) => {
		axios.get(url, {
			responseType: 'blob',
		})
			.then((res) => {
				fileDownload(res.data, filename)
			})
	}

	const handleClickViewKey = () => {
		setShowKey(true);
		setShowKeyId(element.id);
	}

	const handleClickViewSpecies = () => {
		setShowSpecies(true);
	}

	const testUrl = () => {
		axios.get("https://keys.champis.net/"+element.githubUrl)
			.then()
			.catch(() => {
				setUrlError(true);
			});
	}
	useEffect(testUrl);

		return (
			<>
				<KeyDialog keyContent={keyContent} setShowKey={setShowKey} showKey={showKey} />
				<SpeciesDialog speciesList={element.topic.species} showSpecies={showSpecies} setShowSpecies={setShowSpecies}/>

				<ListItem>
					<ListItemIcon>
						<IconButton onClick={() => {handleDownload(element.url, element.realFilename)}}>
							<GetAppIcon/>
						</IconButton>
					</ListItemIcon>
					<ListItemIcon>
						{	element.url.slice(-6) !== "_C.cle" ?
							<IconButton onClick={() => handleClickViewKey()}>
								<VisibilityIcon />
							</IconButton>
							:
							<IconButton>
								<VisibilityOff />
							</IconButton>
						}

					</ListItemIcon>
					<ListItemText
						primary={element.title + " ("+element.languageName+")"}
						secondary={element.author}
					/>
					<ListItemIcon>
						<Button onClick={() => handleClickViewSpecies()}>
							Taxons
						</Button>
					</ListItemIcon>
					<ListItemIcon>
						<IconButton onClick={() => {window.location.href="https://bitbucket.org/SchwabN/keys/src/master/"+element.githubUrl;}}>
							<GitHubIcon/>
						</IconButton>
					</ListItemIcon>
					<ListItemIcon>
						<IconButton onClick={() => {window.location.href="https://champis.net/viewtopic.php?t="+element.topic.id;}} >
							<ForumIcon />
						</IconButton>
					</ListItemIcon>
				</ListItem>
				{ urlError &&
					<ListItem>
						<Alert severity="error">Il y a une incohérence. La clé "{element.title}" est présente dans l'API mais son adresse est introuvable dans le repo GIT.</Alert>
					</ListItem>
				}
			</>
		)
	}

	export default Key;