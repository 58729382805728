import {SITE_ROOT} from "./http-common";
import React, {useEffect, useState} from "react";
import SpeciesService from "./SpeciesService";
import {Loading} from "./Loading";

const SpeciesRow = ({id}) => {

	const [species, setSpecies] = useState(null);
	const [loading, setLoading] = useState(true);

	const retrieveSpecies = () => {
		SpeciesService.getSpecies(id)
			.then((response) => {
				setSpecies(response.data);
				setLoading(false);
			})
			.catch((e) => {
				console.log(e);
			});
	};

	useEffect(retrieveSpecies);

	const AcceptedSpecies = () =>
		<span>
			<span><i>{species.canonicalName}</i><small>{species.author}</small></span>
			<a href={SITE_ROOT + "/species/" + species.id}><i
			className="icon fa fa-external-link fa-fw" aria-hidden="true"/> </a>
		</span>

	const SynonymSpecies = () =>
		<span>
			<AcceptedSpecies />
			( = <i>{species.synonymCanonicalName}</i> <small>{species.synonymAuthor}</small> <a
			href={SITE_ROOT + "/species/" + species.synonymId}><i
			className="icon fa fa-external-link fa-fw" aria-hidden="true"/> </a>)
		</span>


	if (loading) {
		return <Loading />
	} else if (species === null || species.id === 1000000000) {
		return <span />
	} else if (species.synonymId === null) {
		return <AcceptedSpecies/>
	} else {
		return <SynonymSpecies />
	}
}

export default SpeciesRow;