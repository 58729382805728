import axios from "axios";

export default axios.create({
	baseURL: "https://api.champis.net/v1",
	headers: {
		"Content-type": "application/ld+json"
	}
});

export const SITE_ROOT = "https://champis.net";
export const API_ROOT = "https://api.champis.net";