import http from "./http-common";

const getAutocomplete = (params) => {
	return http.get("/species/autocomplete", { params });
};

const getSpecies = (id, params) => {
	return http.get("/species/"+id, { params });
};

export default {
	getAutocomplete,
	getSpecies,
};