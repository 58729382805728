import http from "./http-common";

const getKeys = (params) => {
	return http.get("/keys", { params });
};

const getKey = (id, params) => {
	return http.get("/keys/"+id, { params });
};

export default {
	getKeys,
	getKey
};