import React from "react";
import ListItem from "@material-ui/core/ListItem";
import IconButton from "@material-ui/core/IconButton";
import List from "@material-ui/core/List";
import CloseIcon from "@material-ui/icons/Close";
import Dialog from "@material-ui/core/Dialog";
import SpeciesRow from "./SpeciesRow";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";

const SpeciesDialog = ({speciesList, showSpecies, setShowSpecies}) => {

	const handleClose = () => {
		setShowSpecies(false);
	}

	return (
		<Dialog
			open={showSpecies}
			onClose={() => handleClose()}
			aria-labelledby="alert-dialog-title"
			aria-describedby="alert-dialog-description"
		>
			<List>
				<ListItem>
					<ListItemIcon>
						<IconButton edge="start" onClick={() => handleClose()} aria-label="close">
							<CloseIcon />
						</IconButton>
					</ListItemIcon>
				</ListItem>
					{speciesList.map((species) => (
						<ListItem>
							<ListItemText key={species.gbifId}>
								<SpeciesRow id={species.gbifId} />
							</ListItemText>
						</ListItem>
					))}
			</List>
		</Dialog>
	)
}

export default SpeciesDialog;